.bg-white {
  background: #fff;
}
* {
  font-family: Avenir-Medium, Avenir;
}

/* magin - x auto */
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.te-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* 多行省略 2 ~ 5 */
.te {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: initial;
}

.te-2 {
  -webkit-line-clamp: 2;
}
.te-3 {
  -webkit-line-clamp: 3;
}
.te-4 {
  -webkit-line-clamp: 4;
}
.te-5 {
  -webkit-line-clamp: 5;
}

/* 定位 position */
.pst-rlt {
  position: relative;
}
.pst-absl {
  position: absolute;
}
.pst-absl-i {
  position: absolute !important;
}
.pst-fix {
  position: fixed;
}
.pst-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* 固定定位 */
.fix-h {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

.fix-v {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}

.fix-vh {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* warning-tip */
.pf-warning-tip {
  position: absolute;
  top: -13px;
  right: 25px;
  z-index: 100000;
  padding: 3px 10px;
  background: #fff6d6;
  border-radius: 4px;
  border: 1px solid #ffca09;
  font-size: 12px;
  font-family: Avenir-Medium, Avenir;
  font-weight: 500;
  color: #234b76;
  line-height: 16px;
  display: block;
}

.pf-btn-not-allowed:hover + .pf-warning-tip {
  display: block;
}
.border-right{
  border-right:2px solid #D2D5E1;
}
.border-bottom{
  border-bottom:1px solid #D2D5E1;
}
.border-top{
  border-top:1px solid #D2D5E1;
}
.rotate-n-90{
  transform:rotate(-90deg)
  
}
.rotate-90{
  transform:rotate(90deg)
}
/* flex 相关 */
.flex {
  display: flex !important;
}
.flex-column {
  flex-direction: column;
}
.page-break-avoid {
  page-break-inside: avoid;
}
.page-break-after-avoid {
  page-break-after: avoid;
}
.page-break {
  /* page-break-after:always; */
  margin-bottom: 78px;
  box-shadow: 0px -4px 6px 0px rgba(28, 41, 90, 0.04) !important;
  background: white;
  padding: 38px;
}
.grey-bg {
  background: #f4f4f4 !important;
  padding: 43 43 43 43 !important;
}
.flex-row {
  flex-direction: row;
}
h1 {
  font-size: 18px !important;
  font-family: Avenir-Medium, Avenir;
  font-weight: 800;
  color: #234b76;
  line-height: 40px;
  text-align: center;
}
h2 {
  font-size: 14px !important;
  font-family: Avenir-Medium, Avenir;
  font-weight: 500;
  color: #234b76;
  line-height: 40px;
}
.font-24-bold {
  color: #636e95;
  font-size: 24px;
  width: 80%;
  text-align: center;
  font-weight: 600;
}
.font-24-medium {
  color: #234B76;
  font-size: 24px;
  width: 80%;
  font-family: Avenir-Medium, Avenir;
  text-align: center;
  font-weight: 600;
}
.font-20-medium {
  color: #234B76;
  font-size: 20px;
  width: 80%;
  font-family: Avenir-Medium, Avenir;
  text-align: center;
  font-weight: 600;
}
.font-20-heavy {
  color: #234b76;
  font-size: 20px;
  width: 80%;
  font-family: Avenir-Heavy, Avenir;
}

.font-20-normal {
  color: #636e95;
  font-size: 20px;
  width: 80%;
  font-weight: normal;
  font-family: Avenir-Medium, Avenir;
}
.font-20-normal ul {
  padding: 0;
}
.font-18-bold {
  color: #636e95;
  font-size: 18px;
  width: 80%;
  font-weight: 600;
}
.font-16-bold {
  color: #636e95;
  font-size: 16px;
  width: 80%;
  font-weight: 600;
}

.font-16-normal {
  color: #636e95;
  font-size: 16px;
  width: 80%;
  font-weight: 500;
  font-family: Avenir-Medium, Avenir;
}
.font-geometria{
  font-family: Geometria;
  font-size: 16px;
  color:#194772;
  font-weight: 500;
}
.font-sourcehan{
  font-family: Source Han Sans CN;
  font-size: 16px;
  color:#194772;
  font-weight: 500;
}
.font-sourcehan-light{
  font-family: Source Han Sans CN;
  font-size: 30px;
  color:#194772;
  font-weight: 100;
}
.font-universal{
  font-family: sans-serif;
  font-size: 16px;
  color:black;
  font-weight: 500;
}
.font-16-normal-blue {
  color: #369afe;
  font-size: 16px;
  width: 80%;
  font-family: Avenir-Medium, Avenir;
}

.font-16-normal-dark-blue {
  color: #234b76;
  font-size: 16px;
  font-family: Avenir-Medium, Avenir;
}

.font-14-bold {
  color: #636e95;
  font-size: 14px;
  width: 80%;
  font-weight: 600;
}
.font-14-normal {
  color: #636e95;
  font-size: 14px;
  width: 80%;
  font-family: Avenir-Medium, Avenir;
}
.font-14-dark-blue-normal {
  color: #234b76;
  font-size: 14px;
  font-family: Avenir-Medium, Avenir;
}
.font-grey {
  font-size: 14px;
  font-weight: 600;
  color: #97a0c3;
}
.font-grey-16 {
  font-size: 16px;
  font-weight: 600;
  color: #97a0c3;
}
.font-grey-18 {
  font-size: 18px;
  font-weight: 500;
  color: #97a0c3;
}
.fs-24 {
  font-size: 24px;
}
.fw-500 {
  font-weight: 500;
}
.fw-800 {
  font-weight: 800;
}
.font-20-medium {
  font-size: 20px;
  font-family: Avenir-Medium, Avenir;
}
.font-18-medium {
  font-size: 18px;
  font-family: Avenir-Medium, Avenir;
}
.font-16-medium {
  font-size: 16px;
  font-family: Avenir-Medium, Avenir;
}
.font-heavy {
  font-family: Avenir-Heavy, Avenir;
}
.font-medium {
  font-family: Avenir-Medium, Avenir !important;
}
.font-14-red{
  font-size: 14px;
  font-family: Avenir-Medium, Avenir;
  font-weight: 500;
  color: #FA5087;
}
.no-wrap {
  white-space: nowrap;
}
.flex-around {
  justify-content: space-around;
}
.flex-center {
  justify-content: center;
}
.flex-middle {
  align-items: center;
}
.flex-right {
  justify-content: flex-end;
}
.flex-between {
  justify-content: space-between;
}
.flex-end {
  align-items: flex-end;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-1 {
  flex: 1;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.scroll-bar {
  overflow-y: scroll;
}
.scroll-bar::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #234b76;
}
.scroll-bar::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #d8d8d8;
}
.btn {
  width: 94px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #369afe;
  line-height: 36px;
  text-align: center;
  font-size: 16px;
  font-family: Avenir-Medium, Avenir;
  font-weight: 500;
  color: #369afe;
  cursor: pointer;
  padding: 8px 24px;
  position: relative;
}
.btn:hover {
  color: white;
  border: 1px solid #369afe;
  background: #369afe;
  transition: all 0.3s;
}
.red-btn {
  display: flex;
  width: 94px;
  height: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  text-align: center;
  padding: 8px 24px;
  line-height: 36px;
  cursor: pointer;
  position: relative;
  font-family: Avenir-Medium, Avenir;
  color: #fa5087;
  border: 1px solid #fa5087;
}
.red-btn:hover {
  border: 1px solid #fa5087;
  color: #fa5087;
  background: unset;
}
.blue-btn {
  color: white;
  background: #369afe;
  transition: all 0.3s;
  border: 1px solid #369afe;
  border-radius: 8px;
  margin-left: 35px;
}
.grey-btn {
  display: flex;
  width: 94px;
  height: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  text-align: center;
  padding: 8px 24px;
  line-height: 36px;
  cursor: pointer;
  position: relative;
  color: #97a0c3 !important;
  border-radius: 8px;
  border: unset;
  margin-left: 35px;
  background: #f4f4f4 !important;
}
.grey-btn:hover {
  border: unset;
  color: #97a0c3;
  background: #f4f4f4;
}
.box-shadow-top {
  box-shadow: 0px -4px 6px 0px rgba(28, 41, 90, 0.04) !important;
}
.font20 {
  font-size: 20;
  color: #234b76;
  font-weight: 500;
  font-family: Avenir-Medium, Avenir;
  margin-right: 35;
}

/* Basic Color */
.c-text {
  color: #234b76;
}

/* 通用水平垂直居中 */
.vh-parent {
  position: relative;
}
.v {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.h {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.vh {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* overflow */
.ovfl-hd {
  overflow: hidden;
}
.ovfl-scroll {
  overflow: scroll;
}
.ovfl-vsb {
  overflow: visible;
}
.ovfl-x-hd {
  overflow-x: hidden;
}
.ovfl-x-scroll {
  overflow-x: scroll;
}
.ovfl-y-hd {
  overflow-y: hidden;
}
.ovfl-y-scroll {
  overflow-y: scroll;
}

/* 浮动 */
.fl-l {
  float: left;
}
.fl-r {
  float: right;
}
.cfl::after {
  content: "";
  display: block;
  clear: both;
}

/* 清除浮动 */
.cl-b {
  clear: both;
}
.cl-l {
  clear: left;
}
.cl-r {
  clear: right;
}

/* box-sizing */
.bs-bd {
  box-sizing: border-box;
}
.bs-ct {
  box-sizing: content-box;
}

.vtal-top {
  vertical-align: top;
}
.vtal-md {
  vertical-align: middle;
}
.vtal-bt {
  vertical-align: bottom;
}

/* text-align */
.t-c {
  text-align: center;
}
.t-l {
  text-align: left;
}
.t-r {
  text-align: right;
}

/* 线排列方式 */
.td-none {
  text-decoration: none;
}
.td-lth {
  text-decoration: line-through;
}

/* 水平滑动 */
.scroll-h {
  overflow-x: scroll;
  width: 100%;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

/* 折行 */
.word-wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
}

/* 加粗字体 */
.fw-bold {
  font-weight: bold;
}

/* 入场动画 */
.fadein-init {
  opacity: 0;
  transition: opacity 0.2s ease-in;
}
.fadein {
  opacity: 1;
}
.loading {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.disabled {
  opacity: 0.3;
  /*pointer-events: none;*/
  cursor: default !important;
}
.disabled-btn {
  /*pointer-events: none;*/
  cursor: not-allowed !important;
}

.clickble-btn {
  /*pointer-events: none;*/
  cursor: pointer;
}

.disabled-background {
  background: #d2d5e1 !important;
}

.hide {
  display: none;
}
